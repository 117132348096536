<template>
  <div>
    <el-form
      ref="form"
      :rules="rules"
      :model="parmas"
      label-width="120px"
    >
      <el-form-item label="物流类型：">快递发货</el-form-item>
      <el-form-item
        label="模板名称："
        prop="name"
      >
        <el-input
          style="width: 500px;"
          v-model="parmas.name"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="计费方式："
        prop="valuationType"
      >
        <el-radio-group
          v-model.number="parmas.valuationType"
          :disabled="valuationType"
        >
          <el-radio :label="1">件数</el-radio>
          <el-radio :label="2">按重量</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="配送区域"
        prop="valuationRules"
      >
        <el-table
          class="idefine-table"
          :data="parmas.valuationRules"
        >
          <el-table-column
            label="可配送区域"
            style="width: 340px;"
            property="region"
          ></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="fixValuationRules(scope.$index)"
              >修改</el-button>&nbsp;
              <el-button
                type="text"
                @click="deleteValuationRules(scope.$index)"
              >删除</el-button>
            </template>
          </el-table-column>
          <el-table-column :label="parmas.valuationType==1 ?'首件（个）':'首重（kg）'">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.startStandard"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="运费（元）">
            <template slot-scope="scope">
              <el-input
                @input="scope.row.startFee=scope.row.startFee.match(/^\d*(\.?\d{0,2})/g)[0]||null"
                v-model="scope.row.startFee"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="parmas.valuationType==1 ?'续件（个）':'续重（kg）'">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.addStandard"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="运费（元）">
            <template slot-scope="scope">
              <el-input
                @input="scope.row.addFee=scope.row.addFee.match(/^\d*(\.?\d{0,2})/g)[0]||null"
                v-model="scope.row.addFee"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin: 10px 0 ;">
          <el-button
            type="primary"
            @click="AddValuationRules"
          >新增</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      :visible.sync="visible"
      title="选择区域"
    >
      <div style="height: 380px;">
        <vue-scroll :ops="ops">
          <div class="clearfix">
            <div style="width: 33%;float: left;">
              <el-tree
                :data="area1"
                show-checkbox
                highlight-current
                node-key="label"
                ref="tree"
              ></el-tree>
            </div>
            <div style="width: 33%;float: left;">
              <el-tree
                :data="area2"
                show-checkbox
                highlight-current
                node-key="label"
                ref="tree2"
              ></el-tree>
            </div>
            <div style="width: 33%;float: left;">
              <el-tree
                :data="area3"
                show-checkbox
                highlight-current
                node-key="label"
                ref="tree3"
              ></el-tree>
            </div>
          </div>
        </vue-scroll>
      </div>

      <div slot="footer">
        <el-button type="default">取消</el-button>
        <el-button
          type="primary"
          @click="getCheckedNodes"
        >保存</el-button>
      </div>
    </el-dialog>
    <div style="text-align: center">
      <el-button>返回</el-button>
      <el-button
        type="primary"
        @click="saveAll"
      >保存</el-button>
    </div>
  </div>
</template>

<script>
// import { pcaa } from "area-data";
import { Tree } from 'element-ui'
// import area from '// util/area'
//  import scroll from '// config/scrool'
import { AddDeliveryExpress, DeliveryExpress, UpdateDeliveryExpress } from '@/api/marketing/shop/set'
export default {
  components: {
    ElTree: Tree
  },
  data () {
    let area1 = area.slice(0, parseInt(area.length / 3))
    let area2 = area.slice(
      parseInt(area.length / 3),
      parseInt(area.length / 3) * 2
    )
    let area3 = area.slice(parseInt(area.length / 3) * 2, area.length)
    return {
      currentIndex: 0,
      type: 1,
      // typeStr:this.parmas.valuationType?'个':'kg',
      tableData: [
        {
          param1: '辽宁省',
          param2: '1',
          param3: '0.00',
          param4: '0',
          param5: '100'
        }
      ],
      parmas: {
        name: '',
        valuationType: 1,
        valuationRules: [
          // {
          //   region: "",
          //   regionCode: "",
          //   startStandard: 0,
          //   startFee: 0,
          //   addStandard: 0,
          //   addFee: 0
          // }
        ]
      },
      area1,
      area2,
      area3,
      regionList: [],
      regionCodeList: [],
      ops: scroll,
      visible: false,
      valuationType: this.$route.params.uuid && this.$route.params.uuid !== '',
      rules: {
        name: [
          { required: true, message: '请填写模板名称', trigger: 'blur' }
        ],
        valuationRules: [
          { required: true, message: '请填写配送区域', trigger: 'blur' }
        ],
        valuationType: [
          { required: true, message: '请填写计费方式', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.$route.params.uuid && this.$route.params.uuid !== '') {
        DeliveryExpress(this.$route.params.uuid).then(res => {
          this.parmas = res.data
          this.parmas.valuationRules.map((item) => {
            item.addFee = item.addFee / 100
            item.startFee = item.startFee / 100
            return item
          })
          this.currentIndex = res.data.valuationRules.length
        })
      }
    },
    // 修改
    fixValuationRules (index) {
      this.currentIndex = index
      this.visible = true
    },
    deleteValuationRules (index) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.parmas.valuationRules.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 新增
    AddValuationRules () {
      let valuationRules = {
        region: '',
        regionCode: '',
        startStandard: 0,
        startFee: 0,
        addStandard: 0,
        addFee: 0
      }
      this.parmas.valuationRules.push(valuationRules)
      this.visible = true
      this.currentIndex = this.parmas.valuationRules.length - 1
    },
    // 保存
    saveAll () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }

        let newarr = [
          {
            region: this.regionList.join(','),
            regionCode: this.regionCodeList.join(','),
            startStandard: this.parmas.valuationRules[this.currentIndex].startStandard,
            startFee: this.parmas.valuationRules[this.currentIndex].startFee * 100,
            addStandard: this.parmas.valuationRules[this.currentIndex].addStandard,
            addFee: this.parmas.valuationRules[this.currentIndex].addFee * 100
          }
        ]
        this.parmas.valuationRules.splice(this.currentIndex, 1)
        let obj = {
          name: this.parmas.name,
          valuationType: this.parmas.valuationType,
          valuationRules: newarr.concat(this.parmas.valuationRules)
        }
        this.parmas.valuationRules = newarr.concat(this.parmas.valuationRules)

        if (this.$route.params.uuid && this.$route.params.uuid !== '') {
          UpdateDeliveryExpress(this.$route.params.uuid, obj).then((res) => {
            if (res.code === 0) {
              this.$refs.tree.setCheckedKeys([])
              this.$message({
                type: 'success',
                message: '更改成功!'
              })
              this.$router.go(-1)
            }
          })
        } else {
          AddDeliveryExpress(obj).then((res) => {
            if (res.code === 0) {
              this.$refs.tree.setCheckedKeys([])
              this.$message({
                type: 'success',
                message: '增加成功!'
              })
              this.$router.go(-1)
            }
          })
        }
      })
    },
    // 新建配送区域-弹窗
    getCheckedNodes () {
      this.regionCodeList = []
      this.regionList = []
      let areaData1 = this._filterNodes(this.$refs.tree.getCheckedNodes())
      let areaData2 = this._filterNodes(this.$refs.tree2.getCheckedNodes())
      let areaData3 = this._filterNodes(this.$refs.tree3.getCheckedNodes())
      let allArr = [...areaData1, ...areaData2, ...areaData3]
      allArr.map(item => {
        this.regionCodeList.push(item[0])
        this.regionList.push(item[1])
      })
      this.parmas.valuationRules[this.currentIndex].region = this.regionList.join(',')
      this.parmas.valuationRules[this.currentIndex].regionCode = this.regionCodeList.join(',')
      this.visible = false
    },
    _filterNodes (arr) {
      let tempArr = this._getChildrenCode(arr, [])
      return arr
        .filter(v => {
          if (!tempArr.includes(v.label)) {
            return true
          }
          return false
        })
        .map(v => {
          return [v.code, v.label]
        })
    },
    _getChildrenCode (arr, cacheArr) {
      arr.forEach(val => {
        if (val.hasOwnProperty('children')) {
          val.children.forEach(v => {
            if (!cacheArr.includes(v.label)) {
              cacheArr.push(v.label)
            }
          })
          this._getChildrenCode(val.children, cacheArr)
        }
      })
      return cacheArr
    }

  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped>
</style>
